<template>
  <div>
    <notifications class="mt-4" group="foo" position="top center" />
    <router-view />

    <!-- <base href="https://social.main.pres.brokeree.dev/widgets/">
    <script src="https://social.main.pres.brokeree.dev/widgets/main.js" type="application/javascript" defer v-if="whitelabel === 'GCCBrokers'"></script> -->
  </div>
</template>
<script>
if (process.env.VUE_APP_WHITELABEL === "Blackstone") {
  window.__lc = window.__lc || {};
  window.__lc.license = 6761951;
  (function (n, t, c) {
    function i(n) {
      return e._h ? e._h.apply(null, n) : e._q.push(n);
    }

    var e = {
      _q: [],
      _h: null,
      _v: "2.0",
      on: function () {
        i(["on", c.call(arguments)]);
      },
      once: function () {
        i(["once", c.call(arguments)]);
      },
      off: function () {
        i(["off", c.call(arguments)]);
      },
      get: function () {
        if (!e._h)
          throw new Error(
            "[LiveChatWidget] You can't use getters before load."
          );
        return i(["get", c.call(arguments)]);
      },
      call: function () {
        i(["call", c.call(arguments)]);
      },
      init: function () {
        var n = t.createElement("script");
        (n.async = !0),
          (n.type = "text/javascript"),
          (n.src = "https://cdn.livechatinc.com/tracking.js"),
          t.head.appendChild(n);
      },
    };
    !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
  })(window, document, [].slice);
}
import { publicPath } from "../vue.config";

export default {
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  created() {
    this.handleIcon();
  },
  methods: {
    handleIcon() {
      // accessing the link tag
      const favicon = document.getElementById("favicon");
      if (process.env.VUE_APP_WHITELABEL === "ICMTrader") {
        favicon.href = `${publicPath}favicon-ICMTrader.ico`;
      } else if (process.env.VUE_APP_WHITELABEL === "TD365") {
        favicon.href = `${publicPath}favicon-TD365.ico`;
      } else if (process.env.VUE_APP_WHITELABEL === "TDSouthAfrica") {
        favicon.href = `${publicPath}favicon-TDSouthAfrica.ico`;
      } else if (process.env.VUE_APP_WHITELABEL === "Blackstone") {
        favicon.href = `${publicPath}favicon-Blackstone.ico`;
      } else if (process.env.VUE_APP_WHITELABEL.includes("ICM")) {
        favicon.href = `${publicPath}favicon.ico`;
      } else if (
        process.env.VUE_APP_WHITELABEL.includes("EagleGlobalMarkets")
      ) {
        favicon.href = `${publicPath}favicon-EGM.ico`;
      } else if (process.env.VUE_APP_WHITELABEL.includes("OneTradingMarkets")) {
        favicon.href = `${publicPath}favicon-OTM.ico`;
      } else if (process.env.VUE_APP_WHITELABEL.includes("OrbitInvest")) {
        favicon.href = `${publicPath}favicon-OrbitInvest.ico`;
      } else if (process.env.VUE_APP_WHITELABEL.includes("GCCBrokers")) {
        favicon.href = `${publicPath}favicon-GCCBrokers.ico`;
      } else if (process.env.VUE_APP_WHITELABEL.includes("TradiNext")) {
        favicon.href = `${publicPath}favicon-TradiNext.ico`;
      } else if (process.env.VUE_APP_WHITELABEL.includes("VCGMarkets")) {
        favicon.href = `${publicPath}favicon-VCGMarkets.ico`;
      }else if (process.env.VUE_APP_WHITELABEL.includes("Polaris")) {
        favicon.href = `${publicPath}favicon-PolarisMarkets.ico`;
      }else if (process.env.VUE_APP_WHITELABEL.includes("Dzengi")) {
        favicon.href = `${publicPath}favicon-Dzengi.ico`;
      }

    },
  },
};
</script>
