import Vue from "vue";
import App from "./App.vue";
import "./assets/css/app.scss";
import router from "./router";
import store from "./store";
import "@/assets/css/tailwind.css";
import VueRouter from "vue-router";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import "./extend";
import Notifications from "vue-notification";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueClipboard from "vue-clipboard2";
import JsonCSV from "vue-json-csv";
import { VTooltip } from "v-tooltip";
import CurrencyBadge from "vue-currency-symbol";
import VueSanitize from "vue-sanitize";
import VueI18Next from "@panter/vue-i18next";

import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Backend from "i18next-http-backend";

Vue.directive("tooltip", VTooltip);

var converter = require("number-to-words");
Vue.filter("toWords", function (value) {
  if (!value) return "";
  return converter.toWords(value);
});
Vue.prototype.$filters = Vue.options.filters;

const ICMThemeCss = () => import("./assets/css/icm-overide.scss");
ICMThemeCss();
//
// if (process.env.VUE_APP_WHITELABEL.includes('ICM') || process.env.VUE_APP_WHITELABEL.includes('ICMTrader') || process.env.VUE_APP_WHITELABEL.includes('Promena')) {
//     ICMThemeCss()
// }

if (process.env.VUE_APP_WHITELABEL === "TD365") {
  import("./assets/css/td365-overide.scss");
} else if (process.env.VUE_APP_WHITELABEL === "TDSouthAfrica") {
  import("./assets/css/tdsouth-africa.scss");
} else if (process.env.VUE_APP_WHITELABEL === "Blackstone") {
  import("./assets/css/blackstone.scss");
} else if (
  process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
  process.env.VUE_APP_WHITELABEL === "TradeCoreUK"
) {
  import("./assets/css/EagleGlobalMarkets.scss");
} else if (process.env.VUE_APP_WHITELABEL === "OrbitInvest") {
  import("./assets/css/orbitInvest.scss");
} else if (
  process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
  process.env.VUE_APP_WHITELABEL === "TradiNext"
) {
  import("./assets/css/GCCBrokers.scss");
}

Vue.use(require("number-to-words"));
Vue.use(require("vue-moment"));
Vue.use(VueRouter);
Vue.use(Notifications);
Vue.use(VueClipboard);
Vue.use(VueI18Next);
Vue.use(CurrencyBadge);

// rules for sanitize function
let defaultOptions = {
  allowedTags: [
    "h3",
    "h4",
    "h5",
    "p",
    "ul",
    "ol",
    "nl",
    "li",
    "br",
    "b",
    "a",
    "i",
    "strong",
    "em",
    "hr",
    "div",
    "table",
    "thead",
    "caption",
    "tbody",
    "tr",
    "th",
    "td",
    "pre",
  ],
  allowedAttributes: {
    a: ["href", "name", "target"],
  },
  selfClosing: ["br", "hr"],
  allowedSchemes: ["http", "https"],
  allowedSchemesAppliedToAttributes: ["href", "src", "cite"],
};
Vue.use(VueSanitize, defaultOptions);

// register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("Loading", Loading);
Vue.component("v-select", vSelect);
Vue.component("downloadCsv", JsonCSV);

i18next.default_locale = "en";
const options = {
  backend: {
    loadPath: (lng) => {
      // check if there is no env file
      let url= process.env.VUE_APP_URL

      if (process.env.VUE_APP_WHITELABEL === "OneTradingMarkets") {
        return url + `otm/${lng}.json`;
      } else if (process.env.VUE_APP_WHITELABEL === "GCCBrokers") {
        return url + `gcc/${lng}.json`;
      } else if (process.env.VUE_APP_WHITELABEL === "VCGMarkets") {
        return url + `vcgmarkets/${lng}.json`;
      } else {
        return url + `${lng}.json`;
      }
    },
    crossDomain: true,
    allowMultiLoading: true,
    // addPath: process.env.VUE_APP_URL + `en.json`
  },
  returnEmptyString: false,
  fallbackLng: "en",
  sendMissingTo: "fallback",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  allowMultiLoading: true, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
  // allow credentials on cross domain requests
  withCredentials: false,
  // overrideMimeType sets request.overrideMimeType("application/json")
  overrideMimeType: false,
  reloadInterval: false, // can be used to reload resources in a specific interval (useful in server environments)
};

i18next.use(HttpApi).use(Backend).init(options);

export default i18next;
const i18n = new VueI18Next(i18next);

const lang = localStorage.getItem("lang") || "en";
if (lang === "ar" || lang === "fa") {
  document.getElementById("body").dir = "rtl";
}

new Vue({
  router,
  store,
  i18n: i18n,
  t: i18n.t,
  render: (h) => h(App),
}).$mount("#app");


