import Cookie from "js-cookie";

export default {
  token: Cookie.get("auth_token") || "",
  isLoggedIn: false,
  profile: {},
  profileOptions: [],
  userData: {},
  accountData: [],
  authentication: {
    showNextStep: JSON.parse(localStorage.getItem("step")) || 0,
    signupData: {},
    tradingPlatforms: [],
    tradingDemoPlatforms: [],
  },
  suitable: {},
  demoBackendAccounts: [],
  liveBackendAccounts: [],
  systemCurrencies: [],
  paymentHistory: [],
  transactionHistory: [],
  uploadPresets: [],
  uploadedDocuments: [],
  depositMethods: [],
  paymentsGateway: [],
  bankAccountForms: {},
  bankAccounts: [],
  withdrawalMethods: [],
  availableCards: [],
  cardRequests: [],
  countries: [],
  ibSummary: {},
  trackingLinks: {},
  trackingLinksWhitelabels: [],
  clientList: [],
  subIbClientList: [],
  subIbList: [],
  ibUploadDocuments: [],
  ibProfile: {},
  ibPaymentHistory: [],
  paymentsInfo: {},
  ibWithdrawalMethods: [],
  withdrawRequests: [],
  leadForms: [],
  liveAccounts: [],
  lang: localStorage.getItem("lang") || "",
  en: {},
  showSidebar: false,
  showAddNewBankAccount: false,
  showAlertNav: false,
  alertNavMessage: "",
  selectedAccountId: "",
  annualCheck: false,
  gccShopPopupAfterDemo: false,
  mobileLoginLink: "",
  ssoToken: "",
  icmAcademyModal: false,
  showIcmAcademyModal: true,
  showICMPopup: false,
  showICMProofModal: "",
  icmCapitalSuitableModal: false,
  widgetToken: "",
  zendeskRegular: "",
  limitedStateUpgradeModal: false,
  limitedUserMessage: false,
  proofOfIdModal: false,
  statusChangeMessageModal: false,
};
